export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function notNullish<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function numberOrNull<T>(
  value: T | string | undefined | boolean
): value is T {
  return (
    typeof value !== 'string' &&
    typeof value !== 'boolean' &&
    value !== undefined
  );
}

export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}
