import { JwtPayload, jwtDecode } from 'jwt-decode';
import {
  CarSetupVarsFragment,
  EnumPersonRole,
  useGetPersonInfoQuery,
  useOneracehubProQuery,
  useWhoAmIQuery,
} from '~/generated/graphql';
import config from '../../../config';
import { isTest } from './IsTest';
interface JwtInterface extends JwtPayload {
  role: EnumPersonRole;
  team: string;
}

// TODO refactor these functions into a single getJWTData func??
export function checkLoggedIn() {
  const jwt = config.get('security.jwt') as string;
  if (!jwt) return false;
  const decoded = jwtDecode<JwtInterface>(jwt);
  if (decoded != null && decoded.exp != null) {
    const now = new Date().getTime();
    const exp = new Date(decoded.exp * 1000).getTime();
    return now <= exp;
  }
  return false;
}

export function getRole(): EnumPersonRole | undefined {
  const jwt = config.get('security.jwt') as string;
  if (!jwt) return undefined;
  const decoded = jwtDecode<JwtInterface>(jwt);
  return decoded.role ?? undefined;
}

export function getTeamID(): string | undefined {
  const jwt = config.get('security.jwt') as string;
  if (!jwt) return undefined;
  const decoded = jwtDecode<JwtInterface>(jwt);
  return decoded?.team ?? undefined;
}

export function getUserID(): string | undefined {
  const jwt = config.get('security.jwt') as string;
  if (!jwt) return undefined;
  const decoded = jwtDecode<JwtInterface>(jwt);
  return decoded?.sub ?? undefined;
}

export function checkifIsProUser() {
  let isProUser;
  const { data, isLoading } = useOneracehubProQuery({}, { enabled: !isTest });

  if (isTest) {
    // eslint-disable-next-line no-console
    console.log('proUser has been set to TRUE for testing');
    return { isProUser: true, isLoading };
  }
  if (data?.license.valid && !data.license.features.lightVersion) {
    isProUser = true;
  } else {
    isProUser = false;
  }

  return { isProUser, isLoading };
}

export function hasRequiredRoles(restrictedToRoles?: EnumPersonRole[]) {
  if (restrictedToRoles === undefined || restrictedToRoles === null)
    return true;

  const myRole = getRole();
  if (!myRole) return false;

  return restrictedToRoles.some((x) => x === myRole);
}

export function isTimeTrackerAdmin() {
  const meQuery = useWhoAmIQuery();
  const person = useGetPersonInfoQuery({
    id: meQuery.data?.whoami.claims.sub,
  });
  return person.data?.person?.isTimeTrackerAdmin || false;
}

export function hasRequiredTeam(
  team: string | null | undefined,
  emptyResponse: boolean = true
) {
  if (!team) return emptyResponse;

  const myTeam = getTeamID();

  return myTeam === team;
}

export function isRunSheetEditable(
  runSheet: { isLocked?: boolean | null; teamID?: string } | null | undefined
) {
  if (runSheet?.isLocked === true) {
    return false;
  }
  // An admin can edit all runSheets
  if (hasRequiredRoles([EnumPersonRole.ADMIN])) return true;

  // A setup reviewer is only allowed to edit run sheets if the team is matching and not empty
  if (
    hasRequiredTeam(runSheet?.teamID, false) &&
    hasRequiredRoles([EnumPersonRole.SETUP_REVIEWER])
  )
    return true;

  // engineer can only edit runSheets of their team
  return (
    hasRequiredTeam(runSheet?.teamID) &&
    hasRequiredRoles([
      EnumPersonRole.CAR_ENGINEER,
      EnumPersonRole.CHIEF_ENGINEER,
      EnumPersonRole.MECHANIC,
      EnumPersonRole.TYRE_ENGINEER,
      EnumPersonRole.WORKSHOP_MANAGEMENT,
    ])
  );
}

export function isSetupSheetEditable(
  setupSheet: CarSetupVarsFragment | null | undefined
) {
  // An admin can edit all setupSheets
  if (hasRequiredRoles([EnumPersonRole.ADMIN])) return true;

  // A setup reviewer is only allowed to edit setup sheets if the team is matching and not empty
  if (
    hasRequiredTeam(
      setupSheet?.teamID ?? setupSheet?.runSheet?.teamID,
      false
    ) &&
    hasRequiredRoles([EnumPersonRole.SETUP_REVIEWER])
  )
    return true;
  // engineer can only edit setupSheets of their team
  return (
    hasRequiredTeam(setupSheet?.teamID) &&
    hasRequiredRoles([
      EnumPersonRole.CHIEF_ENGINEER,
      EnumPersonRole.CAR_ENGINEER,
    ])
  );
}
